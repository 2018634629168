import React from "react";
import { Grid } from "@material-ui/core";
import resumeStyles from "../../styles/resume-style";

export function findBestIconSize(screenWidth) {
  if (screenWidth < 420) {
    return 16;
  } else if (screenWidth < 2000) {
    return 24;
  } else {
    return 28;
  }
}

export function createCardList(list) {
  const classes = resumeStyles();
  const content = list.map((item, key) => (
    <Grid
      container
      justifyContent="center"
      direction="row"
      alignItems="center"
      spacing={2}
      key={key}
    >
      <Grid item xs={2} sm={2}>
        <Grid
          container
          className={classes.iconBlock}
          spacing={1}
          justifyContent="space-around"
        >
          {item.icons.map((icon, Ikey) => {
            var xs = 10;
            var sm = 6;
            var md = 4;
            var xl = 3;
            if (icon.type.name === "VMware") {
              sm = 12;
              md = 10;
              xl = 8;
            }
            return (
              <Grid item xs={xs} sm={sm} md={md} xl={xl} key={Ikey}>
                {icon}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid item xs={9} sm={9}>
        <Grid className={classes.place} item xs={12}>
          {item.place}
        </Grid>
        <Grid className={classes.dates} item xs={12}>
          {item.dates}
        </Grid>
        <Grid className={classes.description} item xs={12}>
          {item.description}
        </Grid>
      </Grid>
    </Grid>
  ));
  return content;
}
