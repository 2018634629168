import { makeStyles } from "@material-ui/core/styles";

const resumeStyles = makeStyles((theme) => ({
  rootBox: {
    display: "flex",
    color: "black",
    justifyContent: "center",
    flexGrow: 1,
  },
  scope: {
    marginBottom: "-10px",
  },
  iconBlock: {
    justifyContent: "center",
    direction: "row",
    alignItems: "center",
  },
  icon: {
    size: 64,
  },
  subject: {
    textAlign: "center",
  },
  place: {
    fontSize: "20px",
    marginTop: "10px",
  },
  dates: {
    fontSize: "12px",
  },
  description: {
    fontSize: "16px",
  },
  certLink: {
    color: "black",
    fontWeight: "bold",
    textDecoration: "none",
  },
  snackbarButton: {
    margin: "20px 0",
  },
}));

export default resumeStyles;
