import { makeStyles } from "@material-ui/core/styles";

const NavbarStyles = makeStyles((theme) => ({
  Navbar: {
    position: "fixed",
    width: "100%",
    zIndex: 10,
  },
  navbarAvatar: {
    display: "block",
    margin: "0.5rem auto",
    width: theme.spacing(13),
    height: theme.spacing(13),
  },
  item: {
    color: "white",
  },
  itemText: {
    color: "white",
  },
  sideNav: {
    width: "250px",
    backgroundColor: "#222222",
    height: "100%",
  },
  footerRoot: {
    "& .MuiBottomNavigationAction-root": {
      minWidth: 0,
      maxWidth: 250,
    },
    "& .MuiSvgIcon-root": {
      fill: "white",
      "&:hover": {
        fill: "black",
        fontSize: "1.8rem",
      },
    },
  },
}));

export default NavbarStyles;
