import { makeStyles } from "@material-ui/core/styles";
import Background from "../assets/background.jpg";

const homeStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${Background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100vh",
  },
  mask: {
    backgroundColor: "rgba(0,0,0,0.2)",
    width: "100%",
    height: "100vh",
  },
  particlesCanvas: {
    position: "fixed",
    width: "100%",
    zIndex: -1,
    opacity: 0.4,
  },
  navbarAvatar: {
    display: "block",
    margin: "0.5rem auto",
    width: theme.spacing(13),
    height: theme.spacing(13),
  },
  item: {
    color: "white",
  },
  itemText: {
    color: "white",
  },
}));

export default homeStyles;
