import React from "react";
import { Typography, Avatar, Grid, Box } from "@material-ui/core";
import Typed from "react-typed";
import profileStyles from "../../styles/profile-style.js";
import avatar from "../../assets/me.jpg";

const Profile = () => {
  const classes = profileStyles();
  return (
    <Box className={classes.typedContainer}>
      <Grid container justifyContent="space-around">
        <Box borderRadius="50%">
          <Avatar
            className={classes.headerAvatar}
            src={avatar}
            alt="me"
          ></Avatar>
        </Box>
      </Grid>
      <Typography className={classes.headerTitle} variant="h4">
        Or Navon
      </Typography>
      <br />
      <Typography className={classes.subtitle} variant="h5">
        <Typed
          strings={[
            "DevOps Developer",
            "SRE",
            "Python Developer",
            "Go Developer",
            "React Developer",
          ]}
          typeSpeed={40}
          loop
        />
      </Typography>
    </Box>
  );
};

export default Profile;
