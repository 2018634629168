import React from "react";
import { Grid } from "@material-ui/core";
import resumeStyles from "../../styles/resume-style";
import { createCardList } from "./Helper";
import { Python, Docker, Linux, Jenkins, Ansible, OpenStack, Grafana, Windows, Oracle, MongoDB, C, CPlusPlus, DotNet, CPU, Arduino } from "../icons/Icons.js";

function Education(props) {
  const classes = resumeStyles();
  //const iconSize = findBestIconSize(props.ww);

  const educationList = [
    {
      place: "Basmach - IDF School Of Computer Engineering",
      dates: "4.2019 - 8.2019",
      description:
        "DevOps Developer course. Building projects in python. learning Linux and Linux internals, containers basics implementing with docker, CI process with Jenkins, CD process with Ansible, DevOps methodology fundamentals, web development fundamentals, cloud architecture, OpenStack fundamentals,  monitoring and logging with ELK and Prometheus Grafana stacks, cybersecurity basics including certificate authorities and digital certificates, system administrator fundamentals(Windows and Linux), storage types and protocols, oracle DB infrastructure, MongoDB infrastructure, basics of some of the built-in tools that come with Kali Linux as MSFvenom, Burp, and Volatility. the biggest project I build there was my own multi-agent, multi-masters monitoring tool in python including GUI I wrote with the Kivy python package.",
      icons: [
        <Python />,
        <Linux />,
        <Docker />,
        <Jenkins />,
        <Ansible />,
        <OpenStack />,
        <Grafana />,
        <Windows />,
        <Oracle />,
        <MongoDB />,
      ],
    },
    {
      place: "Magshimim Cyber Education Program",
      dates: "9.2015 - 6.2018",
      description:
        "Learning C, C++, Assembly, and Python programming language writing multiple projects in all of them. Studying Networks fundamentals and protocols. Understanding OS architectures and building a simple demo starting from Nand logical operator. my final project was building my own remote desktop app with python as client-side (the target), C# as server-side(with windows forms .NET framework), and my own image compression algorithm with C++.",
      icons: [
        <C />,
        <CPlusPlus />,
        <CPU />,
        <Python />,
        <DotNet />,
      ],
    },
    {
      place: "Rogozin High School",
      dates: "9.2015 - 6.2018",
      description:
        "10 study units in computer science 5 study units in physics, finishing as an outstanding student. participating in the Makers project where I learned and then taught Arduino. participating in the space program project building a fully functional demo of nanosatellite and lab environment simulating magnetic fields. Both programmed with python.",
      icons: [
        <DotNet />,
        <Arduino />,
        <Python />,
      ],
    },
  ];

  const cardList = () => {
    return createCardList(educationList);
  };

  return (
    <>
      <Grid
        className={classes.subject}
        item
        xs={12}
      >
        <h1 className={classes.scope}>EDUCATION</h1>
      </Grid>
      {cardList()}
    </>
  );
}

export default Education;
