import React from "react";
import homeStyles from "../../styles/home-style.js";
import { MyParticles } from "../Particles";
import Navbar from "../navbar/Navbar";
import Profile from "./Profile";

function Home() {
  const classes = homeStyles();
  return (
    <div className={classes.root}>
      <div className={classes.mask}>
        <Navbar />
        <Profile />
      </div>
      <MyParticles canvasStyle={classes.particlesCanvas} />
    </div>
  );
}

export default Home;
