import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  ListItem,
  ListItemIcon,
  IconButton,
  ListItemText,
  Avatar,
  Divider,
  List,
  Box,
} from "@material-ui/core";
import { AssignmentInd, Home, Menu, ArrowBack } from "@material-ui/icons";
import NavbarStyles from "../../styles/navbar-style.js";
import SwipeableDrawer from "@material-ui/core/Drawer";
import avatar from "../../assets/me.jpg";
import NavFooter from "./NavFooter";

const SideBarItems = [
  { icon: <Home />, text: "Home", path: "/" },
  { icon: <AssignmentInd />, text: "Resume", path: "/resume" },
];

const Navbar = () => {
  const [sideBarState, setSideBarState] = useState({ open: false });

  const openSideBar = () => {
    setSideBarState({ open: true });
  };

  const closeSideBar = () => {
    setSideBarState({ open: false });
  };

  const classes = NavbarStyles();

  const sideBar = () => (
    <div className={classes.sideNav}>
      <Box style={{ paddingLeft: "20px", paddingTop: "9px" }}>
        <IconButton onClick={closeSideBar}>
          <ArrowBack style={{ color: "dark grey" }} />
        </IconButton>
      </Box>
      <Avatar
        className={classes.navbarAvatar}
        src={avatar}
        alt="me"
        component={Link}
        to={"/"}
        onClick={closeSideBar}
      ></Avatar>
      <Divider />
      <List>
        {SideBarItems.map((item, key) => (
          <ListItem
            button
            key={key}
            component={Link}
            to={item.path}
            onClick={closeSideBar}
          >
            <ListItemIcon className={classes.item}>{item.icon}</ListItemIcon>
            <ListItemText
              classes={{ primary: classes.itemText }}
              primary={item.text}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <>
      <Box component="nav" className={classes.Navbar}>
        <AppBar position="static" style={{ background: "#222" }}>
          <Toolbar>
            <IconButton edge="start" onClick={openSideBar}>
              <Menu style={{ color: "grey" }} />
            </IconButton>
            <SwipeableDrawer
              className={classes.sideNavDrawer}
              open={sideBarState.open}
              onClose={closeSideBar}
            >
              {sideBar()}
              <NavFooter />
            </SwipeableDrawer>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default Navbar;
