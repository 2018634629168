import React, { useEffect, useState } from "react";
import { Grid, Box } from "@material-ui/core";
import resumeStyles from "../../styles/resume-style";
import Navbar from "../navbar/Navbar";
import Education from "./Education";
// import Skills from "./Skills";
import WorkExperience from "./WorkExperience";
import CoursesCerts from "./CoursesCerts";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import PrintIcon from "@material-ui/icons/Print";
import CV from "../../or_navon_cv_11_6_2021.pdf";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Resume() {
  const classes = resumeStyles();
  const [windowWidth, setWindowWidth] = useState(0);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  var resumeBoxMX = windowWidth > 800 ? "20%" : "6%";
  return (
    <>
      <Navbar />
      <Box mx={resumeBoxMX} boxShadow={5} className={classes.rootBox}>
        <Grid
          style={{ margin: "100px 0" }}
          container
          justifyContent="center"
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <WorkExperience ww={windowWidth} />
          <Education ww={windowWidth} />
          <CoursesCerts />

          {/* <Button
            download="ornavon-cv.pdf"
            href={CV}
            className={classes.snackbarButton}
            variant="outlined"
            onClick={handleClick}
          >
            <PrintIcon />
          </Button> */}
        </Grid>
      </Box>
      <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Download success
        </Alert>
      </Snackbar>
    </>
  );
}

export default Resume;
