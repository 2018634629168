import React from "react";
import { Grid } from "@material-ui/core";
import {
  Arduino,
  Python,
  ReactJS,
  Django,
  Flask,
  HTML5,
  JavaScript,
  CSS3,
  MongoDB,
  Bamboo,
  Redis,
  OpenShift,
  NodeJS,
  PostgreSql,
  Jenkins,
  K6,
  Ansible,
  Docker,
  K3S,
  Linux,
  Vim,
  Kubernetes,
  GoLang,
  Gitlab,
  AWS,
  RDS,
  EKS,
  GitHub,
  Helm,
  Pixie,
  CircleCI,
  Cloudflare,
  ArgoCD,
  Datadog,
  OPA,
  AWSLambda,
} from "../icons/Icons.js";
import resumeStyles from "../../styles/resume-style";
import { createCardList } from "./Helper";

function WorkExperience(props) {
  const classes = resumeStyles();

  //const iconSize = findBestIconSize(props.ww);

  const ExperienceList = [
    {
      place: "Cypago",
      title: "DevOps Developer",
      dates: "Dec 2021 - Present",
      description: (
        <>
          Acting as a one-man show in the field of DevOps and developing parts
          of the main app in GoLang. Implementing multiple cloud-native
          solutions to common challenges in the cloud-native microservice app
          world.
          <ul>
            <li>
              Moving the entire infrastructure from Elastic Beanstalk to EKS,
              all with IAC with Terraform and helm charts(infra and
              applicative).
            </li>
            <li> Developing multiple integrations using GoLang.</li>
            <li>
              Implementing multiple cloud-native solutions, including:
              <ul>
                <li>Full monitoring solution using Prometheus and Grafana.</li>
                <li>
                  Logging solution with cost reduction filters using Datadog.
                </li>
                <li>
                  Auto service publish system using AWS Load Balancer ingress
                  controller with external DNS.
                </li>
                <li>
                  Continuos Profiling and network deep monitoring using Pixie.
                </li>
                <li>Full GitOps ecosystem using ArgoCD</li>
              </ul>
            </li>
            <li> Refactoring the whole CI/CD process using CircleCI.</li>
            <li> Creating and supporting ML processes.</li>
            <li> Managing multiple EKS clusters in various regions.</li>
            <li>
              {" "}
              Implementing multiple security and misconfiguration detection
              solutions in the CI flow, including: Snyk, Trivy, Datree
            </li>
          </ul>
        </>
      ),
      icons: [
        <GoLang />,
        <Docker />,
        <EKS />,
        <Kubernetes />,
        <AWS />,
        <RDS />,
        <Python />,
        <GitHub />,
        <Helm />,
        <Pixie />,
        <CircleCI />,
        <Datadog />,
        <Cloudflare />,
        <ArgoCD />,
        <OPA />,
        <AWSLambda />,
      ],
    },
    {
      place: "CheckPoint",
      title: "DevOps Developer",
      dates: "Jul 2021 - Dec 2021",
      description: (
        <>
          Working on the Infinity Next product. Maintaining and developing in a
          full microservices environment running on EKS integrating with various
          AWS services (MSK, Neptune, OpenSearch, lambdas, IAM, ...).
          <ul>
            <li>
              Rearchitecting and extending Gitlab CI pipelines libraries and
              infrastructure.
            </li>
            <li>
              Developing and deploying end-to-end cost reduction solution
              including both AWS and Kubernetes auto scalers. Creating a
              management web GUI and automated scenarios tests with k3s.
            </li>
            <li>
              Upgrading multiple monitoring and observability solutions all
              cloud-native(Prometheus, Grafana, ...) with k8s operators and helm
              charts.
            </li>
            <li> Helping teams with their Jenkins pipelines.</li>
            <li>
              Creating mimicable IaaC environment using Terraform and Helm.
            </li>
          </ul>
        </>
      ),
      icons: [
        <GoLang />,
        <Flask />,
        <ReactJS />,
        <Gitlab />,
        <Docker />,
        <EKS />,
        <Kubernetes />,
        <AWS />,
        <RDS />,
        <Python />,
      ],
    },
    {
      place: "Ofek Unit IAF",
      title: "DevOps Developer",
      dates: "Jan 2021 - Jul 2021",
      description: (
        <>
          Developing DevOps solutions and tools, Assimilating new tools, and
          building the standard solutions in the aspect of the full DevOps
          workflow in my unit.
          <ul>
            <li>
              Building KPI applications with Node.js, React.js, and Python.
            </li>
            <li>
              Researching, implementing, and guiding in the field of performance
              testing with the k6 platform as part of the CI.
            </li>
            <li>
              Deploying Jenkins for production( HA, automated backups,
              auto-scaled, with custom image suitable for the on-prem
              environment) on both Docker and Kubernetes infrastructures) and
              maintain it.
            </li>
            <li> Helping teams with their Jenkins pipelines.</li>
            <li>
              Transforming inner automation tools into Ansible playbooks and
              controlling it with AWX.
            </li>
            <li> deploying k3 clusters for new tools tests. </li>
          </ul>
        </>
      ),
      icons: [
        <NodeJS />,
        <Flask />,
        <ReactJS />,
        <PostgreSql />,
        <Bamboo />,
        <Docker />,
        <K6 />,
        <OpenShift />,
        <Jenkins />,
        <Ansible />,
        <K3S />,
        <Python />,
      ],
    },
    {
      place: "Ofek Unit IAF",
      title: "OpenShift Administrator and Full Stack Developer",
      dates: "JAN 2020 - JAN 2021",
      description: (
        <>
          Serving as an OpenShift administrator and building full stack apps.
          <ul>
            <li>
              Learning advanced networks (achieving the knowledge to
              troubleshoot and analyze network traffic of common protocols with
              Wireshark), advanced Linux (including advances internals, Vim,
              Bash, yum, and many more tools and commands), advanced Docker,
              advanced Kubernetes, and OpenShift basics.
            </li>
            <li> Maintaining OpenShift Lab and development environments.</li>
            <li>
              Teaching and guiding new team members in the field of OpenShift.
              including writing a new onboarding path and creating multiple
              tasks, labs and tests.
            </li>
            <li>
              Building OCheck 1 a monolith that inspect OpenShift clusters and
              checks for configuration problems and standards issues. Written in
              flask and web native stack(HTML5, CSS3 and Javascript).
            </li>
            <li>
              Releasing OCheck 2 a SaaS solution to monitor standards in the
              OpenShift environment built in the microservice architecture with
              the full DevOps methodologies implemented in the development and
              deployment processes. Including building stateless scalable
              microservices with full CI/CD pipelines(testing, building the
              container image and pushing to the OpenShift registry with
              bamboo). I used Flask(for development, for production I used
              uWSGI), React.js, MongoDB and Redis as a message queue.
            </li>
          </ul>
        </>
      ),
      icons: [
        <Linux />,
        <Vim />,
        <Docker />,
        <Kubernetes />,
        <OpenShift />,
        <Flask />,
        <ReactJS />,
        <MongoDB />,
        <Bamboo />,
        <JavaScript />,
        <HTML5 />,
        <CSS3 />,
        <Redis />,
      ],
    },
    {
      place: "OOS",
      title: "Frontend Developer",
      dates: "11.2018 - 2.2019",
      description:
        "Working as React.js developer, building a full 3d objects store flow including the possibility to upload .obj or .stl files, displaying them in 3d, sending them to material price calculating in the backend and selling them in the store.",
      icons: [<ReactJS />, <Django />],
    },
    {
      place: "Rogozin High School",
      title: "Instructor",
      dates: "7.2018 - 9.2018",
      description:
        "Instructing new students in the space project and keep developing new versions of the first nano-satellite.",
      icons: [<Arduino />, <Python />],
    },
  ];

  const cardList = () => {
    return createCardList(ExperienceList);
  };

  return (
    <>
      <Grid className={classes.subject} item xs={12}>
        <h1 className={classes.scope}>EXPERIENCE</h1>
      </Grid>
      {cardList()}
    </>
  );
}

export default WorkExperience;
