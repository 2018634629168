import React from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

export const MyParticles = (props) => {
  const canvasStyle = props.canvasStyle;
  const particlesInit = async (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  return (
    <Particles
      init={particlesInit}
      canvasClassName={canvasStyle}
      params={{
        particles: {
          links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: "none",
            enable: true,
            outModes: {
              default: "bounce",
            },
            random: false,
            speed: 0.5,
            straight: false,
          },
          number: {
            value: 50,
            density: {
              enable: true,
              value_area: 900,
            },
          },
          shape: {
            type: ["circle"],
            stroke: {
              width: 2,
              color: "#b0b0b0",
            },
          },
          size: {
            value: 4,
            random: true,
            anim: {
              enable: true,
              speed: 4,
              size_min: 0.1,
              sync: true,
            },
          },
          opacity: {
            value: 1,
            random: true,
            anim: {
              enable: true,
              speed: 1,
              opacity_min: 0.3,
              sync: true,
            },
          },
        },
        interactivity: {
          detect_on: "window",
          events: {
            onclick: { enable: true, mode: "push" },
            resize: true,
          },
          modes: {
            push: { particles_nb: 4 },
          },
        },
      }}
    />
  );
};
