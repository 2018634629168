import React from "react";
import { Grid, Typography } from "@material-ui/core";
//import { SiKubernetes, SiPython, SiReact, SIGit } from "react-icons/si";
import resumeStyles from "../../styles/resume-style";
//import { findBestIconSize, createCardList } from "./Helper";

function CoursesCerts(props) {
  const classes = resumeStyles();

  const coursesAndCerts = [
    {
      place: "Cloud Native Computing Foundation",
      content: "CKS",
      url: "https://ti-user-certificates.s3.amazonaws.com/e0df7fbf-a057-42af-8a1f-590912be5460/4b3a07fe-9bef-5e23-8efc-ab69d7d3861f-or-navon-43d251f3-0b56-498e-a846-76ffe71c8423-certificate.pdf",
    },
    {
      place: "HashiCorp",
      content: "Terraform Associate",
      url: "https://www.credly.com/badges/6451ad07-5b5e-4140-be03-de12ea656a2d",
    },
    {
      place: "Cloud Native Computing Foundation",
      content: "CKAD",
      url: "https://ti-user-certificates.s3.amazonaws.com/e0df7fbf-a057-42af-8a1f-590912be5460/4b3a07fe-9bef-5e23-8efc-ab69d7d3861f-or-navon-certified-kubernetes-application-developer-ckad-certificate.pdf",
    },
    {
      place: "Cloud Native Computing Foundation",
      content: "CKA",
      url: "https://ti-user-certificates.s3.amazonaws.com/e0df7fbf-a057-42af-8a1f-590912be5460/4b3a07fe-9bef-5e23-8efc-ab69d7d3861f-or-navon-certified-kubernetes-administrator-cka-certificate.pdf",
    },
    {
      place: "IITC",
      content: "Linux System",
    },
    {
      place: "Pluralsight",
      content: "Git Fundamentals",
    },
    {
      place: "Pluralsight",
      content: "Advanced Python",
    },
    {
      place: "Pluralsight",
      content: "Python Beyond the Basics",
    },
    {
      place: "Pluralsight",
      content: "Exploring Web Scraping with Python",
    },
    {
      place: "Udemy",
      content: "Modern React with Redux",
    },
  ];

  const createCertAndCoursesList = () => {
    function certOrCourse(item) {
      if (item.url) {
        return (
          <div>
            {item.place} - &nbsp;
            <a className={classes.certLink} href={item.url}>
              {item.content}
            </a>
          </div>
        );
      } else {
        return (
          <div>
            {item.place} - {item.content}
          </div>
        );
      }
    }
    const content = coursesAndCerts.map((item, key) => (
      <Grid
        key={key}
        container
        justifyContent="center"
        direction="row"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={2} sm={2}></Grid>
        <Grid item xs={9} sm={9}>
          <Grid className={classes.place} item xs={12}>
            <Typography component={"span"}>{certOrCourse(item)}</Typography>
          </Grid>
        </Grid>
      </Grid>
    ));
    return content;
  };

  return (
    <>
      <Grid className={classes.subject} item xs={12}>
        <h1 className={classes.scope}>Courses and Certificates</h1>
      </Grid>
      {createCertAndCoursesList()}
    </>
  );
}

export default CoursesCerts;
