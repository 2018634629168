import React from "react";
import { Link } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { GitHub, LinkedIn } from "@material-ui/icons";
import NavbarStyles from '../../styles/navbar-style.js';

const NavFooter = () => {
  const classes = NavbarStyles();
  return (
    <BottomNavigation width="auto" style={{ background: "#222" }}>
      <BottomNavigationAction
        component={Link}
        className={classes.footerRoot}
        style={{ padding: 0 }}
        icon={<GitHub />}
        to={"//github.com/nofearOnline"}
        target="_blank"
      />
      <BottomNavigationAction
        component={Link}
        className={classes.footerRoot}
        style={{ padding: 0 }}
        icon={<LinkedIn />}
        to={"//www.linkedin.com/in/or-navon-55aab9190/"}
        target="_blank"
      />
    </BottomNavigation>
  );
};

export default NavFooter;