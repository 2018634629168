import { makeStyles } from "@material-ui/core/styles";

const ProfileStyles = makeStyles((theme) => ({
  headerAvatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    margin: theme.spacing(1),
  },
  headerTitle: {
    color: "white",
    fontFamily: "Rubik",
    fontWeight: 600,
  },
  subtitle: {
    color: "white",
    marginBottom: "3rem",
    fontFamily: "Rubik",
    fontWeight: 300,
  },
  typedContainer: {
    position: "absolute",
    top: "calc(50% + 50px)",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    zIndex: 1,
    width: "fit-content",
    minWidth: "220px",
    backgroundColor: "#262623aa",
    borderRadius: "10px",
  },
}));

export default ProfileStyles;
