import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./components/home/Home.js";
import Resume from "./components/resume/Resume.js";
import CssBaseline from "@material-ui/core/CssBaseline";

function App() {
  return (
    <div className="MyCV">
      <CssBaseline />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/resume" element={<Resume />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
